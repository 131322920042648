import * as _ from 'modules/util'
import * as L from 'partial.lenses'
import * as R from 'ramda'

export const makeMaxLengthValidation =
  ({ label, maxLength }) =>
  value => {
    if (!value) return true

    const over = value.length - maxLength
    return value.length > maxLength
      ? `${label} is ${over} ${_.pluralize('character', over)} over the limit of ${maxLength}`
      : true
  }

export const makeRequiredValidation =
  ({ label, message, when: whenIn = true }) =>
  (value, formData) => {
    const when = _.isFunction(whenIn) ? whenIn(formData) : whenIn
    if (!when) return true

    const condition = _.thru(
      value,
      R.cond([
        [_.isString, R.pipe(R.trim, R.isEmpty, R.not)],
        [_.isNumber, R.T],
        [Number.isNaN, R.T],
        [_.isObject, L.isDefined(L.values)],
        [_.isArray, L.isDefined(L.elems)],
        [_.isBoolean, R.equals(true)],
        [R.T, R.F],
      ])
    )

    return condition || message || requiredValidationMessage(label)
  }

const requiredValidationMessage = (labelIn = '') => {
  const label = labelIn.toLowerCase()
  const article = ['a', 'e', 'i', 'o', 'u'].includes(label[0]) ? 'an' : 'a'
  return `You didn’t enter ${article} ${label}`
}

export const email = value => {
  if (_.isBlank(value)) return true
  if (!/^.+@/i.test(value)) return 'Your email address is missing an @'
  if (!/^.+@.+\.[A-Z]{2,}$/i.test(value)) {
    return 'Your email address is missing part of the url at the end (for example gmail.com)'
  }
  return true
}

export const number = value => {
  if (_.isBlank(value)) return true
  if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(value)) return 'You didn’t enter a number'
  return true
}

export const url = value => {
  if (_.isBlank(value)) return 'Provide a URL'
  if (!/^https?:\/\//.test(value)) return 'URL should start with http:// or https:// protocol'
  if (!/([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}/.test(value)) return 'URL is missing a domain name'
}
