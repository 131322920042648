import * as _ from 'modules/util'
import Banner from './banner'

const OutdatedBrowser = ({ dismissible, doDismissForDays }) => {
  const actions = [
    { children: 'Help me update', href: '/browser', reload: true, target: '_blank' },
    _.when(dismissible, { children: 'Remind me later', onClick: doDismissForDays(7) }),
  ]

  return (
    <Banner actions={actions} type="warning">
      <strong>Your browser is quite old</strong> and missing functionality Cliniko needs to work
      properly. We recommend you update soon.
    </Banner>
  )
}

export default OutdatedBrowser
