import * as _ from 'modules/util'
import Banner from './banner'

const ObsoletePlatform = ({ dismissible, doDismissForDays }) => {
  const actions = [
    {
      children: 'Help me update',
      href: '/browser',
      reload: true,
      target: '_blank',
    },
    _.when(dismissible, {
      children: 'Remind me later',
      onClick: doDismissForDays(7),
    }),
  ]

  return (
    <Banner actions={actions} type="warning">
      Your operating system is quite old and will soon be unable to use Cliniko. We recommend you
      update soon.
    </Banner>
  )
}

export default ObsoletePlatform
