import * as S from 'apps/cliniko/style'
import CircleButton from 'components/circle-button'
import * as Icon from 'components/icon'
import Prose from 'components/prose'
import * as E from 'modules/element'
import * as _ from 'modules/util'
import { isValidElement, useRef } from 'react'

const Input = ({
  border = true,
  icon = {},
  id,
  onClear,
  onClearLabel = 'Clear input value',
  prefix,
  ref: refIn,
  suffix,
  type = 'text',
  ...props
}) => {
  const { ref: prefixRef, width: prefixWidth } = E.useResizeObserver()
  const { ref: suffixRef, width: suffixWidth } = E.useResizeObserver()
  const inputRef = useRef()
  const ref = _.useMergeRefs([refIn, inputRef])

  const { disabled, 'aria-invalid': invalid } = props
  const { left: IconLeft, right: IconRight } = isValidElement(icon) ? { left: icon } : icon

  if (!(IconLeft || IconRight || onClear || prefix || suffix)) {
    return <input id={id} css={inputStyles({ border })} ref={ref} type={type} {...props} />
  }

  return (
    <div
      css={containerStyles({
        disabled,
        iconLeft: IconLeft,
        iconRight: IconRight,
        invalid,
      })}>
      {!!prefix && (
        <Prose
          as="span"
          ref={prefixRef}
          css={[{ top: S.rem(S.units(1) - 1) }, iconStyles({ disabled, invalid, left: true })]}>
          {prefix}
        </Prose>
      )}
      {IconLeft}
      <input
        id={id}
        ref={ref}
        type={type}
        css={inputStyles({
          border,
          iconLeft: !!IconLeft,
          iconRight: !!IconRight || !!onClear,
          prefix,
          prefixWidth,
          suffix,
          suffixWidth,
        })}
        {...props}
      />
      {!!suffix && (
        <Prose
          as="span"
          ref={suffixRef}
          css={[{ top: S.unit(1) }, iconStyles({ disabled, invalid, left: false })]}>
          {suffix}
        </Prose>
      )}
      {!!onClear && (
        <CircleButton
          aria-controls={id}
          color={`${_.cond([disabled, 'grey'], [invalid, 'red'], ['blue'])}-borderless`}
          compact
          css={{ position: 'absolute', ...S.space({ top: 0.5, right: 1 }) }}
          className="input-clear-button"
          disabled={disabled}
          icon={<Icon.X />}
          label={onClearLabel}
          onClick={() => {
            onClear()
            inputRef.current.focus()
          }}
          tooltip
        />
      )}
      {IconRight}
    </div>
  )
}

const containerStyles = ({ iconLeft, iconRight, ...iconParams }) => [
  {
    display: 'inline-block',
    width: '100%',
    position: 'relative',
  },
  _.cond(
    [
      iconLeft && iconRight,
      {
        '& > svg:nth-of-type(1)': iconStyles({ ...iconParams, left: true }),
        '& > svg:nth-of-type(2)': iconStyles({ ...iconParams, left: false }),
      },
    ],
    [iconLeft, { '& > svg': iconStyles({ ...iconParams, left: true }) }],
    [iconRight, { '& > svg': iconStyles({ ...iconParams, left: false }) }]
  ),
]

const iconStyles = ({ disabled, invalid, left }) => ({
  bottom: 0,
  height: '100%',
  position: 'absolute',
  zIndex: 1,
  color: _.cond([invalid, S.colors.red()], [disabled, S.colors.grey()], [S.colors.blue()]),
  pointerEvents: 'none',
  ...S.space(left ? 'left' : 'right', 2),
})

const inputStyles = ({
  border,
  iconLeft,
  iconRight,
  prefix,
  prefixWidth,
  suffix,
  suffixWidth,
} = {}) => [
  {
    visibility: !_.isTest() && ((prefix && !prefixWidth) || (suffix && !suffixWidth)) && 'hidden',
    display: 'inline-block',
    width: '100%',
    appearance: 'none',
    borderRadius: S.borderRadius,
    background: 'white',
    color: S.colors.grey(),
    boxSizing: 'border-box',
    paddingTop: S.rem(7),
    paddingBottom: S.rem(9),
    ...S.space({ fontSize: 2, lineHeight: 3, px: 2, scrollMarginTop: 2 }),

    '&[data-focus-visible-added]': {
      ...S.insetBorder({ color: S.colors.focus, width: 2 }),
      outline: 'none',
      zIndex: S.zIndex('rootElements', 'page'),
    },

    '&:disabled': {
      backgroundColor: S.colors.grey(15),
      color: S.colors.grey(),
      cursor: 'not-allowed',
    },

    '&:read-only': {
      backgroundColor: S.colors.grey(15),
      color: S.colors.grey(),
    },

    '&[aria-invalid="true"]': {
      backgroundColor: S.colors.red(11),
      color: S.colors.red(),

      '&::placeholder': {
        color: S.colors.red(7),
      },
    },

    '&::placeholder': {
      color: S.colors.grey(7),
    },

    '&[placeholder]': { textOverflow: 'ellipsis' },

    '&:placeholder-shown': {
      ...S.space.pr(2),

      '~ .input-clear-button': {
        display: 'none',
      },
    },

    '&::selection': {
      color: S.colors.grey(),
      background: S.colors.blue(9),
    },
  },
  !!border && {
    ...S.insetBorder({ width: 1, color: S.colors.neutral(-6) }),
    '&:disabled': S.insetBorder(),
    '&[aria-invalid="true"]': S.insetBorder({ width: 2 }),
  },
  prefixWidth && { paddingLeft: S.rem(prefixWidth + S.units(3)) },
  suffixWidth && { paddingRight: S.rem(suffixWidth + S.units(3)) },
  S.space([iconLeft && 'pl', iconRight && 'pr'], 4.5),
]

export default Input
