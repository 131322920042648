import Input from 'components/input'
import LegacyTextInput from './text-input.legacy'

const TextInput = ({ legacyStyle, ref, ...props }) =>
  legacyStyle ? (
    <LegacyTextInput ref={ref} {...props} />
  ) : (
    <Input ref={ref} type="text" {...props} />
  )

export default TextInput
