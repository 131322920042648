import * as S from 'apps/cliniko/style'
import Icon from 'components/icon.legacy'
import Input from 'components/input.legacy'
import PlainButton from 'components/plain-button'
import * as _ from 'modules/util'

const TextInput = ({ icon, ref, ...props }) =>
  _.isPresent(icon) ? (
    <div css={{ position: 'relative' }}>
      <Input ref={ref} type="text" {...props} />
      <PlainButton css={buttonStyles} onClick={icon.onClick}>
        <Icon
          name={icon.name}
          height="10"
          width="10"
          css={[{ fill: S.legacy.colors.bodyFontLight }, icon.styles]}
        />
      </PlainButton>
    </div>
  ) : (
    <Input ref={ref} type="text" {...props} />
  )

const buttonStyles = [
  {
    lineHeight: S.rem(35),
    position: 'absolute',
    zIndex: 1,
    right: 0,
  },
  S.space.px(1),
]

export default TextInput
