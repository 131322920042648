import { useSuspenseQuery } from '@tanstack/react-query'
import * as T from 'modules/time'
import * as _ from 'modules/util'
import UserAgent from 'resources/user-agent'
import Banner from './banner'

const ObsoleteBrowser = ({ dismissible, doDismissForDays }) => {
  const { data: userAgent } = useSuspenseQuery(
    UserAgent.queryOptions({
      staleTime: Infinity,
    })
  )

  const banishedInDays = T.daysFromNowToString(userAgent.browser.banishedOn)

  const actions = [
    {
      children: 'Help me update',
      href: '/browser',
      reload: true,
      target: '_blank',
    },
    _.when(dismissible, {
      children: 'Remind me later',
      onClick: doDismissForDays(1),
    }),
  ]

  return (
    <Banner actions={actions} type="warning">
      <strong>Oh no! Your web browser is very old.</strong>{' '}
      {banishedInDays
        ? `In ${banishedInDays}, you’ll be unable to use Cliniko on this browser.`
        : 'Cliniko may not work properly on this browser.'}{' '}
      You should update right away.
    </Banner>
  )
}

export default ObsoleteBrowser
