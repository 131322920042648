import * as S from 'apps/cliniko/style'
import * as Icon from 'components/icon'
import Prose from 'components/prose'
import LegacyCheckbox from './checkbox.legacy'

const Checkbox = ({ children, className, label = children, legacyStyle, ref, ...props }) => {
  if (legacyStyle) {
    return <LegacyCheckbox className={className} label={label} ref={ref} {...props} />
  }

  return (
    <label className={className} css={containerStyles}>
      <input css={hiddenInputStyles} ref={ref} type="checkbox" {...props} />
      <div role="presentation" className="fake-checkbox" css={fakeCheckboxStyles}>
        <Icon.Tick bold css={checkMarkStyles} />
      </div>
      <Prose as="span" css={labelStyles}>
        {label}
      </Prose>
    </label>
  )
}

const containerStyles = {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  cursor: 'pointer',
}

const fakeCheckboxStyles = {
  position: 'relative',
  bottom: S.rem(2),
  boxSizing: 'border-box',
  color: 'white',
  cursor: 'pointer',
  display: 'inline-flex',
  justifyContent: 'center',
  width: S.unit(3),
  height: S.unit(3),
  marginRight: S.unit(2),
  borderRadius: S.borderRadius,
  backgroundColor: 'white',
  border: `1px solid ${S.colors.neutral(-6)}`,
  zIndex: 1,

  'input:checked + &': {
    backgroundColor: S.colors.blue(0),
    borderColor: S.colors.blue(0),
  },

  'input:disabled + &': {
    backgroundColor: S.colors.grey(15),
    borderColor: S.colors.grey(10),
    color: S.colors.grey(10),
    cursor: 'not-allowed',
  },

  'input[disabled]:checked + &': {
    backgroundColor: S.colors.grey(15),
    borderColor: S.colors.grey(10),
    cursor: 'not-allowed',
  },

  'input[aria-invalid="true"] + &': {
    backgroundColor: S.colors.red(11),
    borderColor: S.colors.red(0),
    color: S.colors.red(0),
  },

  'input[data-focus-visible-added] + &': {
    backgroundColor: 'white',
    borderColor: S.colors.focus,
    boxShadow: `0 0 0 1px ${S.colors.focus}`,
    color: S.colors.focus,
  },
}

const checkMarkStyles = {
  display: 'none',

  'input:checked + .fake-checkbox > &': {
    display: 'inline',
  },

  'input[data-focus-visible-added] + .fake-checkbox > &': {
    strokeWidth: S.rem(2),
  },
}

const labelStyles = {
  color: S.colors.grey(0),
  cursor: 'pointer',
  userSelect: 'none',

  'input:disabled ~ &': {
    color: S.colors.grey(10),
    cursor: 'not-allowed',
  },
}

const hiddenInputStyles = {
  opacity: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  width: S.unit(3),
  height: S.unit(3),
  userSelect: 'none',
  zIndex: 1,
}

export default Checkbox
