import * as _ from 'modules/util'
import { use } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { ArrayItemContext } from './array/context'

const HiddenField = ({ defaultValue = null, name: nameIn, ...props }) => {
  const { arrayName, index } = use(ArrayItemContext) ?? {}
  const name = [arrayName, index, nameIn].filter(_.isPresent).join('.')

  const { unregister } = useFormContext()
  const { field } = useController({ defaultValue, name })

  _.useEffectOnce(() => () => unregister(name))

  return <input type="hidden" {...field} {...props} value={field.value ?? ''} />
}

export default HiddenField
