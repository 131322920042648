import * as S from 'apps/cliniko/style'

const Input = ({ className: _className, ref, ...props }) => (
  <input css={inputStyles} ref={ref} {...props} />
)

const inputStyles = {
  ...S.bodyFont,
  display: 'inline-block',
  width: '100%',
  appearance: 'none',
  paddingTop: S.rem(7),
  paddingRight: S.unit(1),
  paddingBottom: S.rem(5),
  paddingLeft: S.unit(1),
  border: `1px solid ${S.legacy.colors.inputLine}`,
  borderRadius: S.borderRadius,
  background: '#ffffff',
  fontSize: S.rem(16),
  color: S.legacy.colors.bodyFont,
  boxSizing: 'border-box',

  '&[data-focus-visible-added]': {
    zIndex: S.zIndex('rootElements', 'page'),
    outline: 'none',
    borderColor: S.legacy.colors.primaryLighter,
    boxShadow: `0 0 0 2px ${S.legacy.colors.primaryLightest}`,
  },

  '&:disabled': {
    backgroundColor: S.legacy.colors.secondaryMuchLighter,
    borderColor: S.legacy.colors.secondaryMuchLighter,
    cursor: 'not-allowed',
  },

  '&[aria-invalid="true"]': {
    borderColor: S.legacy.colors.warningBorder,
    boxShadow: `0 0 0 ${S.rem(2)} ${S.legacy.colors.warning}`,
  },
}

export default Input
