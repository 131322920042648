import * as S from 'apps/cliniko/style'
import Prose from 'components/prose.legacy'

const Checkbox = ({
  children,
  className,
  defaultChecked = false,
  label = children,
  ref,
  ...props
}) => (
  <label className={className} css={containerStyles}>
    <input
      className="checkbox-input"
      css={inputStyles}
      defaultChecked={defaultChecked}
      ref={ref}
      type="checkbox"
      {...props}
    />
    <Prose as="span" css={labelStyles}>
      {label}
    </Prose>
  </label>
)

const containerStyles = {
  position: 'relative',
  display: 'inline-block',
  cursor: 'pointer',
}

const inputStyles = {
  opacity: 0,
  position: 'absolute',
  top: 0,
  left: 0,
  width: 'auto',
  cursor: 'pointer',
  userSelect: 'none',
}

const labelStyles = {
  display: 'flex',
  fontWeight: 'normal',
  paddingTop: S.rem(5),
  paddingBottom: S.rem(5),
  color: S.legacy.colors.bodyFont,
  marginRight: 0,
  lineHeight: S.rem(22),
  cursor: 'pointer',
  userSelect: 'none',

  '&:before': {
    content: '""',
    position: 'relative',
    display: 'block',
    top: S.rem(2),
    flexShrink: 0,
    boxSizing: 'border-box',
    width: S.rem(16),
    height: S.rem(16),
    marginRight: S.rem(9),
    borderRadius: S.rem(4),
    background: S.legacy.colors.secondaryLightest,
    border: `1px solid ${S.legacy.colors.secondaryLighterStill}`,
  },

  '.checkbox-input:disabled + &': {
    color: S.legacy.colors.bodyFontLight,
    cursor: 'not-allowed',
  },

  '.checkbox-input:checked + &:before': {
    backgroundColor: S.legacy.colors.primary,
    backgroundImage:
      'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CgogPGc+CiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPgogIDxyZWN0IGZpbGw9Im5vbmUiIGlkPSJjYW52YXNfYmFja2dyb3VuZCIgaGVpZ2h0PSIxNiIgd2lkdGg9IjE2IiB5PSItMSIgeD0iLTEiLz4KIDwvZz4KIDxnPgogIDx0aXRsZT5MYXllciAxPC90aXRsZT4KICA8cGF0aCBzdHJva2U9Im51bGwiIHN0cm9rZS13aWR0aD0iMCIgZmlsbD0iI2ZmZmZmZiIgaWQ9InN2Z18xIiBkPSJtNS41NDIwNjQsMTAuNTM4MjczYy0wLjE3MjUzMSwwIC0wLjM3MzgxNywtMC4wNTc1MSAtMC41MTc1OTMsLTAuMjAxMjg2bC0yLjg3NTUxNSwtMi44NzU1MTVjLTAuMjg3NTUyLC0wLjI4NzU1MiAtMC4yODc1NTIsLTAuNzQ3NjM0IDAsLTEuMDA2NDNjMC4yODc1NTIsLTAuMjg3NTUyIDAuNzQ3NjM0LC0wLjI4NzU1MiAxLjAwNjQzLDBsMi4zNTc5MjMsMi4zNTc5MjNsNS4yMzM0MzgsLTUuMjMzNDM4YzAuMjg3NTUyLC0wLjI4NzU1MiAwLjc0NzYzNCwtMC4yODc1NTIgMS4wMDY0MywwYzAuMjg3NTUyLDAuMjg3NTUyIDAuMjg3NTUyLDAuNzQ3NjM0IDAsMS4wMDY0M2wtNS43NTEwMzEsNS43NTEwMzFjLTAuMDg2MjY1LDAuMTQzNzc2IC0wLjI4NzU1MiwwLjIwMTI4NiAtMC40NjAwODIsMC4yMDEyODZ6Ii8+CiA8L2c+Cjwvc3ZnPg==)',
    borderColor: S.legacy.colors.primary,
  },

  '.checkbox-input[data-focus-visible-added] + &:before': {
    border: `1px solid ${S.legacy.colors.primary}`,
    boxShadow: `0 0 0 ${S.rem(2)} ${S.rgba(S.legacy.colors.primaryLightest, 1)}`,
  },

  '.checkbox-input[aria-invalid="true"] + &:before': {
    borderColor: S.legacy.colors.warningBorder,
    boxShadow: `0 0 0 ${S.rem(2)} ${S.legacy.colors.warning}`,
  },
}

export default Checkbox
